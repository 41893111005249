import api from "../../services/api";
import {
    StyledButton,
    CheckContainer,
    StyledLabel,
    FunctionCheck,
    StyledCheck,
    StyledCheckActivaded,
} from "./styles";

type CheckProps = {
    text?: string;
    isChecked: boolean;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
};


export default function CheckBox({ isChecked, setIsChecked }: CheckProps) {
    const colorStyle ={
        color: "#FFFFFF",
    }

    

    const downloadLGPD = async () => {
        await api.get("get/LGPDText").then(async(response) => {
            const base64URL = response.data;
            const binary = atob(base64URL.replace(/\s/g, ""));
            const len = binary.length;
            const buffer = new ArrayBuffer(len);
            const view = new Uint8Array(buffer);

            for (let i = 0; i < len; i += 1) {
                view[i] = binary.charCodeAt(i);
            }

            // create the blob object with content-type "application/pdf"
            const blob = new Blob([view], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);

            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = "LGPD-Oculare.pdf";
            // a.target = "_blank";
            a.click();
        })
    }

  return (
    <CheckContainer>
        <StyledLabel>
            <FunctionCheck type="checkbox" checked={isChecked} onChange={() => {
                setIsChecked((prev) => !prev)
            }}/>
            {isChecked ? 
                <StyledCheckActivaded className="fa fa-check-square" style={colorStyle}/>
                : 
                <StyledCheck className="fa fa-square" style={colorStyle}/>
            }
        </StyledLabel>
        <StyledButton type="button" onClick={downloadLGPD} >Aceito os termos e políticas de privacidade</StyledButton>
    </CheckContainer>
  );
}