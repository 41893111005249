import Doctor1 from "../assets/Médicos Oculare/Dr1.jpg"
import Doctor2 from "../assets/Médicos Oculare/Dr2.jpg"
import Doctor3 from "../assets/Médicos Oculare/Dr3.jpg"
import Doctor4 from "../assets/Médicos Oculare/Dr4.jpg"
import Doctor5 from "../assets/Médicos Oculare/Dr5.jpg"
import Doctor6 from "../assets/Médicos Oculare/Dr6.jpg"
import Doctor7 from "../assets/Médicos Oculare/Dr7.jpg"
import Doctor8 from "../assets/Médicos Oculare/Dr8.jpg"
import Doctor9 from "../assets/Médicos Oculare/Dr9.jpg"
import Doctor10 from "../assets/Médicos Oculare/Dr10.jpg"
import Doctor11 from "../assets/Médicos Oculare/Dr11.jpg"
import Doctor12 from "../assets/Médicos Oculare/Dr12.jpg"
import Doctor13 from "../assets/Médicos Oculare/Dr13.jpg"
import Doctor14 from "../assets/Médicos Oculare/Dr14.jpg"
import Doctor15 from "../assets/Médicos Oculare/Dr15.jpg"
import Doctor16 from "../assets/Médicos Oculare/Dr16.jpg"
import Doctor17 from "../assets/Médicos Oculare/Dr17.jpg"
import Doctor18 from "../assets/Médicos Oculare/Dr18.jpg"
import Doctor19 from "../assets/Médicos Oculare/Dr19.jpg"
import Doctor20 from "../assets/Médicos Oculare/Dr20.jpg"
import Doctor21 from "../assets/Médicos Oculare/Dr21.jpg"
import Doctor22 from "../assets/Médicos Oculare/Dr22.jpg"
import Doctor23 from "../assets/Médicos Oculare/Dr23.webp"
import Doctor24 from "../assets/Médicos Oculare/Dr24.webp"
import Doctor25 from "../assets/Médicos Oculare/Dr25.webp"
import Doctor26 from "../assets/Médicos Oculare/Dr26.webp"
import DoctorDefault from "../assets/Médicos Oculare/DrDefault.jpg"

export const doctorOptions = [
    "Dr. Marco Daier",
    "Dr. Samuel de Faria Silva Duarte",
    "Dr. Talles Petterson",
    "Dra. Letícia Cazar",
    "Dr. Yuri Oliveira",
    "Dra. Daniela Couto",
    "Dra. Thais Oliveira",
    "Dra. Cristina Souza",
    "Dra. Júlia Tibério",
    "Dra. Micheline Cresta",
    "Dra. Raianni Pires", 
    "Dra. Melina Oliveira",
    "Dra. Gisele Morato",
    "Dra. Adriana Costa", 
    "Dr. Antônio Márcio Luciano",
    "Dra. Cristiana Bertin",     
    "Dr. Jadir Macedo", 
    "Dra. Maria Araceli Oliveira", 
    "Dr. Tulio Reis", 
    "Dra. Marina Albuquerque", 
    "Dra. Junia França", 
    "Dra. Juliana Rocha",
    "Dr. Fernando Cresta",
    //"Dr. Leonardo da Cunha Silva Braga",
    //"Dra. Julia Mendonca Ponte Souza",
    "Dra. Isabela Rita de Carvalho Cunha",
    "Opt. Islara Machado",
];
export const doctorDescription = [

    {
        name: "Dr. Isabela Rita de Carvalho Cunha",
        image: Doctor26,
        description: [
            "CRM-DF 21265;",
            "Oftalmopediatria.;",
            "Neuroftalmologia;",
            ]
    },
    
    {
        name: "Dr. Leonardo da Cunha Silva Braga",
        image: Doctor25,
        description: [
            "CRM-DF 21286;",
            "RQE 19269;",
            "Oftalmologia Geral.;",
            "Retina e vítreo.;",
            "Cirurgia de catarata."
        ]
    },
    {
        name: "Dra. Julia Mendonca Ponte Souza",
        image: Doctor24,
        description: [
            "CRM-DF 26167;",
            "RQE 20789;",
            "Oftalmopediatria.;",
            "Estrabismo.;",
            "Neuroftalmo"
        ]
    },
    {
        name: "Dr. Samuel de Faria Silva Duarte",
        image: Doctor23,
        description: [
            "CRM-DF 19931;",
            "RQE 14027;",
            "Oftalmologia geral;",
            "Retina e vítreo clinico/cirúrgico;"
        ]
    },
    {
        name: "Dra. Adriana Costa",
        image: Doctor1,
        description: [
            "CRM-DF 9171;",
            "RQE 2609;",
            "Oftalmologia geral;",
            "Transplante de córnea;",
            "Lentes de contato."
        ]
    },
    {
        name: "Dra. Maria Araceli Oliveira",
        image: Doctor2,
        description: [
            "CRM-DF 7432;",
            "RQE 4792",
            "Oftalmologia geral;",
            "Lentes de contato."
        ]

    }, 
    {
        name :"Dra. Cristiana Bertin",
        image: Doctor3,
        description: [
            "CRM-DF 9183;",
            "RQE 2537;",
            "Oftalmologia geral;",
            "Neuroftalmologia;",
            "Glaucoma."
        ]
    },
    {
        name :"Dr.  Tulio Reis",
        image: Doctor4,
        description: [
            "CRM-DF 18641;",
            "RQE 14048;",
            "Oftalmologia geral;",
            "Catarata;",
            "Glaucoma."
        ]
    },        
    {
        name :"Dr.  Jadir Macedo",
        image: Doctor5,
        description: [
            "CRM-DF 5743;",
            "RQE 4987;",
            "Oftalmologia geral;",
            "Catarata."
        ]
    },
    {
        name :"Dr.  Antônio Márcio Luciano",
        image: Doctor6,
        description: [
            "CRM-DF 7180;",
            "RQE 7003;",
            "Cirurgia refrativa;",
            "Córnea e superfície ocular;",
            "Oftalmologia geral.",
        ]
    },
    {
        name: "Dra. Raianni Pires",
        image: Doctor7,
        description: [
            "CRM-DF 11155;",
            "RQE 3616;",
            "Oftalmologia geral;",
            "Glaucoma;",
            "Lentes de contato."
        ]
    },       
    {
        name: "Opto. Islara Machado",
        image: Doctor8,
        description: [
            "CBORT 0500/0375;",
            "Ortóptica."
        ]
    },       
    {
        name: "Dra. Melina Oliveira",
        image: Doctor9,
        description: [
            "CRM-DF 12650;",
            "RQE 7458;",
            "Oftalmologia geral;",
            "Retina e vítreo."
        ]
    }, 
    
    {
        name: "Dra. Gisele Morato",
        image: Doctor11,
        description: [
            "CRM-DF 19514;",
            "RQE 14858;",
            "Oftalmologia geral;",
            "Oculoplástica."
        ]
    },
    
    {
        name: "Dra. Raissa Lacerda",
        image: Doctor12,
        description: [
            "CRM-DF 23519;",
            "Oftalmologia geral;",
            "Oculoplástica."
        ]
    },
    {
        name: "Dr. Yuri Oliveira",
        image: Doctor13,
        description: [
            "CRM-DF 24600;",
            "RQE 20465;",
            "Oftalmologia geral.",
        ]
    },

    {
        name: "Dr.  Fernando Cresta",
        image: DoctorDefault,
        description: [
            "CRM-DF 28162;",
            "RQE 19149;",
            "Córnea;",
            "Catarata."
        ]
    },

    {
        name: "Dra. Juliana Rocha",
        image: DoctorDefault,
        description: [
            "CRM-DF 11567;",
            "RQE 6432;",
            "Estrabismo;",
            "Oftalmopediatria."
        ]
    },

    {
        name: "Dra. Junia França",
        image: DoctorDefault,
        description: [
            "CRM-DF 18679;",
            "RQE 14867;",
            "Oftalmologia geral;",
            "Estrabismo;",
            "Oftalmopediatria."
        ]
    },

    {
        name: "Dra. Marina Albuquerque",
        image: Doctor17,
        description: [
            "CRM-DF 18498;",
            "RQE 17104;",
            "Oftalmologia geral;",
            "Oncologia ocular e ultrassom;",
            "Retina e vítreo."
        ]
    },

    {
        name: "Dra. Letícia Cazar",
        image: Doctor15,
        description: [
            "CRM-DF 19552;",
            "RQE 11665;",
            "Oftalmologia Geral;",
            "Oftalmopediatria;",
            "Estrabismo.",
        ]
    },

    {
        name: "Dra. Micheline Cresta",
        image: Doctor21,
        description: [
            "CRM-DF 12736;",
            "RQE 6560;",
            "Córnea.",
        ]
    },

    {
        name: "Dr. Talles Petterson",
        image: Doctor14,
        description: [
            "CRM-DF 23905;",
            "RQE 15820;",
            "Córnea.",
        ]
    },
    

    {
        name: "Dra. Cristina Souza",
        image: Doctor16,
        description: [
            "CRM-DF 13460;",
            "RQE 5671;",
            "Oftalmopediatria.",
        ]
    },


    {
        name: "Dra. Thais Oliveira",
        image: Doctor20,
        description: [
            "CRM-DF  25420;",
            "RQE 20794;",
            "Cirurgia refrativa;",
            "Córnea."
        ]
    },
    

    {
        name: "Dra. Júlia Tibério",
        image: Doctor19,
        description: [
            "CRM-DF 22390;",
            "RQE 20725;",
            "Oftalmologia geral."
        ]
    },


    {
        name: "Dra. Daniela Couto",
        image: Doctor18,
        description: [
            "CRM-DF 10268;",
            "RQE 3082;",
            "Oftalmologia geral."
        ]
    },


    {
        name: "Dr. Marco Daier",
        image: Doctor22,
        description: [
            "CRM-DF 18585;",
            "RQE 16375;",
            "Oftalmologia geral;",
            "Cirurgia Plástica Ocular."
        ]
    },
    


    {
        name :"Dr. Thyago Mota",
        image: Doctor10,
        description: [
            "CRM-DF 9999;",
            "RQE 1111;",
            "Músico;",
            "Lensnet;",
            "MOBA."
        ]
    }

     ,{
        name: "Dr. Marco Daier",
        image: Doctor22,
        description: [
            "CRM-DF 18585;",
            "RQE 16375;",
            "Oftalmologia geral;",
            "Cirurgia Plástica Ocular."
        ]
    },
];

